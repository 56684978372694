import { Link } from 'gatsby';
import { Col, Row } from 'react-awesome-styled-grid';
import { ThemeProvider } from 'styled-components';

import CaptionedTitle from '../components/CaptionedTitle';
import Page from '../components/Page';
import PageHeader from '../components/PageHeader';
import { lightTheme } from '../styles/themes';

const NotFoundPage = () => (
  <Page>
    <ThemeProvider theme={lightTheme}>
      <PageHeader>
        <Row>
          <Col>
            <CaptionedTitle as="h2">404 - Page not found &rsquo;😞&rsquo;</CaptionedTitle>
          </Col>
        </Row>
        <Row>
          <Col lg={8}>
            <div>We couldn&apos;t find what you are looking for.</div>
            <div>
              Wanna go <Link to="/">home</Link>?
            </div>
          </Col>
        </Row>
      </PageHeader>
    </ThemeProvider>
  </Page>
);

export default NotFoundPage;
